import { useState } from 'react';
import axios from 'axios';
import {Form, Button, Col, InputGroup, Stack, Badge, FormText} from 'react-bootstrap';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';

const StepOne = ({ formData, setFormData, nextStep }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [companySize, setCompanySize] = useState(50);
    const [termsStatus, setTermsStatus] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [error, setError] = useState('');
    const [isAdminMode, setIsAdminMode] = useState(false);
    const [domainSuggestion, setDomainSuggestion] = useState([]);
    const domainPrefix = 'myesky.ca';
    const countries = [
        "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina",
        "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
        "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina",
        "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cape Verde", "Cambodia",
        "Cameroon", "Canada", "Qatar", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo (Republic of)",
        "Congo (Democratic Republic of)", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czechia (Czech Republic)",
        "Denmark", "Djibouti", "Dominica", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea",
        "Estonia", "Eswatini", "Spain", "United States", "Ethiopia", "Fiji", "Philippines", "Finland", "France",
        "Gabon", "Gambia", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti",
        "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy",
        "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kyrgyzstan", "Kiribati", "North Korea",
        "South Korea", "Kosovo", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
        "Luxembourg", "Madagascar", "Malaysia", "Malawi", "Maldives", "Mali", "Malta", "Morocco", "Mauritius",
        "Mauritania", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Mozambique",
        "Myanmar (Burma)", "Namibia", "Nauru", "Nepal", "Nicaragua", "Niger", "Nigeria", "Norway", "New Zealand",
        "Oman", "Netherlands", "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Poland",
        "Portugal", "Rwanda", "Romania", "Russia", "Saint Kitts and Nevis", "San Marino", "Saint Lucia",
        "Sao Tome and Principe", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Syria", "Somalia",
        "South Africa", "Sudan", "South Sudan", "Sweden", "Switzerland", "Taiwan", "Tanzania", "Thailand", "East Timor",
        "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine",
        "United Arab Emirates", "United Kingdom", "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela", "Vietnam", "Yemen",
        "Zambia", "Zimbabwe"
    ];

    const isOnlyLetters = (str) => str.length > 1 ? !(/^[a-zA-Z]+$/.test(formData.lmsUrl)) : false
    const isOnlyLettersAndDots = (str) => str.length > 1 ? !(/^[a-zA-Z.]+$/.test(formData.lmsUrl)) : false

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (e.target.name === 'companySize') {
            setCompanySize(e.target.value);
        }

        if (e.target.name === 'name' && e.target.value === 'E_SKY_ADMIN') {
            let password = window.prompt('Enter the password');
            if (password === 'E_SKY_ADMIN') {
                setIsAdminMode(true);
            }
        }

        setFormData({ ...formData, [name]: value });
    };

    const handleCompanyNameChange = async (e) => {
        const {value} = e.target;
        setFormData({...formData, companyName: value});

        if (value.length > 3) {
            try {
                const endpoint = process.env.REACT_APP_API_SUGGEST_DOMAIN;
                const response = await axios.get(`${endpoint}?company_name=${value}`);

                if (response.data.length > 0) {
                    setDomainSuggestion(response.data);
                }
            } catch (err) {

            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!termsStatus && !isAdminMode) {
            setModalShow(true);
            return;
        }

        if (!isAdminMode && isOnlyLetters(formData.lmsUrl)) {
            return;
        } else if (isOnlyLettersAndDots(formData.lmsUrl)) {
            return;
        }

        if (isAdminMode) {
            formData.admin = true;
        }

        setError('');
        setIsLoading(true);
        try {
            const endpoint = isAdminMode ? process.env.REACT_APP_API_CONFIRM_EMAIL : process.env.REACT_APP_API_SUBMIT;
            const response = await axios.post(endpoint, formData);
            if (response.data.success) {
                if (!isAdminMode) {
                    nextStep(1)
                } else {
                    nextStep(2)
                }
            } else {
                setError(response.data.error);
            }
        } catch (err) {
            setError('An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Form onSubmit={handleSubmit} className='step-one-form'>
            {
                !isAdminMode &&
                <Form.Group as={Col} controlId="formName">
                    <Form.Label column sm={10}>Name</Form.Label>
                    <Col sm={12}>
                        <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required/>
                    </Col>
                </Form.Group>
            }
            {
                !isAdminMode &&
                <Form.Group as={Col} controlId="formLastname">
                    <Form.Label column sm={10}>Lastname</Form.Label>
                    <Col sm={12}>
                        <Form.Control type="text" name="lastname" value={formData.lastname} onChange={handleChange} required />
                    </Col>
                </Form.Group>
            }
            {
                !isAdminMode &&
                <Form.Group as={Col} controlId="formEmail">
                    <Form.Label column sm={10}>Email</Form.Label>
                    <Col sm={12}>
                        <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
                    </Col>
                </Form.Group>
            }
            {
                !isAdminMode &&
                <Form.Group as={Col} controlId="formCompanyName">
                    <Form.Label column sm={10}>Company Name</Form.Label>
                    <Col sm={12}>
                        <Form.Control type="text" name="companyName" value={formData.companyName} onChange={handleCompanyNameChange} required />
                    </Col>
                </Form.Group>
            }
            {
                !isAdminMode &&
                <Form.Group as={Col} controlId="formCompanySize">
                    <Form.Label column sm={10}>Company Size</Form.Label>
                    <Col sm={12}>
                        <Form.Control type="range" name="companySize" min="1" max="1000" value={formData.companySize} onChange={handleChange} required />
                        <div className="range-label">
                            <span>1</span>
                            <Form.Text className="range-output">{companySize}</Form.Text>
                            <span>10000</span>
                        </div>
                    </Col>
                </Form.Group>
            }
            {
                !isAdminMode &&
                <Form.Group as={Col} controlId="formPhone">
                    <Form.Label column sm={10}>Phone</Form.Label>
                    <Col sm={12}>
                        <Form.Control type="text" name="phone" value={formData.phone} onChange={handleChange} required />
                    </Col>
                </Form.Group>
            }
            {
                !isAdminMode &&
                <Form.Group as={Col} controlId="formCountry">
                    <Form.Label column sm={10}>Country</Form.Label>
                    <Col sm={12}>
                        <Form.Control as="select" name="country" value={formData.country} onChange={handleChange} required>
                            <option value="">Select Country</option>
                            {countries.map((country) => {
                                return <option key={country} value={country}>{country}</option>
                            })}
                        </Form.Control>
                    </Col>
                </Form.Group>
            }
            <Form.Group as={Col} controlId="formLmsUrl">
                <Form.Label column sm={10}>LMS URL</Form.Label>
                <Col sm={12}>
                    <InputGroup>
                        <InputGroup.Text>
                            https://
                        </InputGroup.Text>
                        <Form.Control type="text" name="lmsUrl" value={formData.lmsUrl} onChange={handleChange} required isInvalid={!isAdminMode ? isOnlyLetters(formData.lmsUrl) : isOnlyLettersAndDots(formData.lmsUrl)}/>
                        {
                            !isAdminMode &&
                            <InputGroup.Text>{domainPrefix}</InputGroup.Text>
                        }
                        <Form.Control.Feedback type="invalid">
                            LMS name is invalid
                        </Form.Control.Feedback>
                        {
                            domainSuggestion.length !== 0 &&
                            <>
                                <Form.Label column={12} sm={10} style={{"paddingTop": "1rem"}}>Suggested domains:</Form.Label>
                                <Stack direction="horizontal" gap={2} sm={12} style={{overflow: "hidden", display: "flex", "flexWrap": "wrap", "paddingTop": ".5rem"}}>
                                    {
                                        domainSuggestion.map((suggestion,index) => {
                                            return (
                                                <Badge key={index} bg="primary">{suggestion}</Badge>
                                            )
                                        })
                                    }
                                </Stack>
                            </>
                        }
                    </InputGroup>
                </Col>
            </Form.Group>
            {
                !isAdminMode &&
                <Form.Group as={Col} controlId="formTermsAndConditions" style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '14px' }}>
                    <Form.Check
                        type='checkbox'
                        defaultChecked={termsStatus}
                        id='terms-and-conditions'
                        onChange={() => setTermsStatus(!termsStatus)}
                    />
                    <Form.Label column sm={10} style={{ display: 'flex' }}>
                        I accept the&nbsp;<strong style={{ cursor: 'pointer' }} onClick={() => setModalShow(true)}>terms and conditions</strong>
                    </Form.Label>
                </Form.Group>
            }
            {error && <p style={{ color: 'red', gridColumn: 'span 2' }}>{error}</p>}

            <Button className='submit-step-1' type="submit" disabled={isLoading || (!termsStatus && !isAdminMode)}>
                {isLoading ? 'Submitting...' : 'Next'}
            </Button>

            <TermsAndConditions
                show={modalShow}
                onHide={() => {
                    !termsStatus ? setError('You must accept the terms and conditions') : setError('');
                    setModalShow(false)
                }}
                termsStatus={termsStatus}
                onChange={() => setTermsStatus(!termsStatus)}
            />
        </Form>
    );
};

export default StepOne