import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Spinner from '../Spinner/Spinner';

const StepThree = ({ platformUrl, loading }) => {
    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px' }}>
            {loading ?
                <>
                    <div style={{ gridColumn: '2 / 3' }}><Spinner /></div>
                    <span style={{ gridColumn: '2 / 3' }}>Generating platform...</span>
                </> :
                <>
                    <Button as={Link} to={platformUrl} target="_blank" rel="noopener noreferrer">Access Platform</Button>
                    <Button as={Link} target="_blank" rel="noopener noreferrer" to={platformUrl ? `${platformUrl}/main/admin/register_template.php` : ''}>Customize</Button>
                    <Button as={Link} target="_blank" rel="noopener noreferrer" to={platformUrl ? `${platformUrl}/main/admin/licence.php` : ''}>Licence</Button>
                </>
            }
        </div>
    );
};

export default StepThree;